import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-detail-overlay',
    templateUrl: './detail-overlay.component.html',
    styleUrls: ['./detail-overlay.component.scss'],
    standalone: false
})
export class DetailOverlayComponent implements OnInit {

    @Input() isOpen = false;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();

    private host: any;

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.host = this.elementRef.nativeElement;
    }

    onClose(): void {
        this.isOpen = false;
        this.host.classList.remove('is-open');
        this.close.emit();
    }

}
