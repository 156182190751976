import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {SidebarService} from './shared/services/sidebar/sidebar.service';
import {SidebarInterface} from './shared/models/abstract/sidebar.interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {

    public showSidebar = false;
    public showOverlay = false;

    private isRefreshed = true;

    constructor(
        private router: Router,
        private sidebarService: SidebarService,
    ) {
    }

    closeSidebar(): void {
        this.showSidebar = false;
        this.sidebarService.closeSidebar();
    }

    closeOverlay(): void {
        this.showOverlay = false;
        this.router.navigate([{outlets: {overlay: null}}]);
    }

    checkCloseAfterRefresh(sidebarComponent: SidebarInterface) {
        if (this.isRefreshed && sidebarComponent.sidebarConfig.closeAfterRefresh) {
            this.sidebarService.closeSidebar();
        }

        this.isRefreshed = false;
    }
}
