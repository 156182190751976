import {
    APP_BASE_HREF,
    CommonModule,
    CurrencyPipe,
    DatePipe,
    getLocaleNumberSymbol,
    NumberSymbol,
    PercentPipe,
    registerLocaleData
} from '@angular/common';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import localeES from '@angular/common/locales/es';
import localeJA from '@angular/common/locales/ja';
import localeNL from '@angular/common/locales/nl';
import localeZH_S from '@angular/common/locales/zh-Hans';
import localeZH_T from '@angular/common/locales/zh-Hant';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    DEFAULT_CURRENCY_CODE,
    ErrorHandler,
    LOCALE_ID,
    NgModule
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import moment from 'moment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {registerLocales} from './core/models/locale';
import {CoreModule} from './core/core.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from './shared/shared.module';
import {OrderManagementService} from './shared/services/order-service/order-management.service';
import {CustomErrorHandler} from './shared/services/custom-error-handler';
import {WhereToService} from './shared/services/platform-service/where-to.service';
import {LocaleService} from './core/services/locale.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DialogService} from './shared/services/dialog-service/dialog.service';
import {environment} from '../environments/environment';
import {DetailOverlayComponent} from './core/components/detail-overlay/detail-overlay.component';
import {DetailSidebarComponent} from './core/components/detail-sidebar/detail-sidebar.component';
import {PrintService} from './shared/services/print-service/print.service';

import {DownloadManagerService} from './shared/services/download-manager-service/download-manager.service';
import {StatusModule} from './status/status.module';
import {PageModule} from './page/page.module';
import {AppService} from './app.service';
import {ComponentLibraryModule} from './component-library/component-library.module';
import {NGX_CURRENCY_CONFIG} from 'ngx-currency';
import {PlatformService} from './shared/services/platform-service/platform.service';

registerLocaleData(localeNL, 'nl');
registerLocaleData(localeDE, 'de');
registerLocaleData(localeFR, 'fr');
registerLocaleData(localeES, 'es');
registerLocaleData(localeJA, 'ja');
registerLocaleData(localeZH_S, 'zh-s');
registerLocaleData(localeZH_T, 'zh-t');
registerLocales();
moment.locale('nl');

@NgModule({
    declarations: [
        AppComponent,
        DetailOverlayComponent,
        DetailSidebarComponent,
    ],
    exports: [
        BrowserModule,
        CommonModule,
        CoreModule,
        SharedModule,
        RouterModule
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
        CommonModule,
        CoreModule,
        StatusModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        ComponentLibraryModule.forRoot({
            ORDER_DISTRIBUTION_API_URL: environment.CM_ORDER_DISTRIBUTION_URL,
            RESOURCE_API_URL: environment.CM_RESOURCE_API_URL,
            ORDER_DISTRIBUTION_EXTERNAL_API_URL: environment.CM_ORDER_DISTRIBUTION_EXTERNAL_URL
        }),
        PageModule], providers: [
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler,
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            deps: [AppService],
            useFactory: (appService: AppService) => {
                return appService.config('organisation').currencyId;
            }
        },
        {
            provide: APP_BASE_HREF,
            deps: [WhereToService],
            useFactory: (whereToService: WhereToService) => whereToService.getBaseUrl()
        },
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (localeService: LocaleService) => {
                return localeService.getLanguage();
            }
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        },
        CurrencyPipe,
        DatePipe,
        PercentPipe,
        DialogService,
        OrderManagementService,
        DownloadManagerService,
        PrintService,
        ComponentLibraryModule,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (appService: AppService, platformService: PlatformService) => {
                return () => new Promise<void>((resolve) => {
                    const initializeAppService = async () => {
                        const account = (window as any).getCmAccount();
                        const technicalLinks = account.TechnicalLinks ?? [];
                        const technicalLink = platformService.getTicketingTechnicalLinkId(technicalLinks);

                        platformService.setAccount(account);
                        platformService.setTechnicalLinkId(technicalLink);

                        await appService.initializeApp();
                    };
                    const header = document.querySelector('platform-header');

                    if (header.classList.contains('platform-loaded')) {
                        initializeAppService().finally(() => resolve());
                    }

                    header.addEventListener('loaded', async () => {
                        initializeAppService().finally(() => resolve());
                    }, {once: true});
                });
            },
            deps: [AppService, PlatformService]
        },
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: NGX_CURRENCY_CONFIG,
            deps: [AppService, LocaleService],
            useFactory: ((appService: AppService, localeService: LocaleService) => {
                const currencyId = appService.config('organisation').currencyId;
                const localeId = localeService.getLanguage();

                const currencySymbol = (0).toLocaleString(localeId, {
                    style: 'currency',
                    currency: currencyId,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }).replace(/\d/g, '').trim();

                return {
                    prefix: currencySymbol,
                    decimal: getLocaleNumberSymbol(localeId, NumberSymbol.Decimal),
                    thousands: getLocaleNumberSymbol(localeId, NumberSymbol.Group),
                    nullable: true,
                };
            })
        }
    ]
})

export class AppModule {
}
